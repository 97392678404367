import React  from "react";
import "./RabbitHoleSearch.scss";
import searchIcon from "../../asset/icons/search-icon.svg";

function RabbitHoleSearch({ rBHoles, changeOrder, refresh }) {
  const submit = (e) => {
    e.preventDefault();
    if (e.target.search.value === '') {
      refresh(!refresh);
      refresh(refresh);

    } else {
      const filtered = rBHoles.filter((hole) =>
        hole.hole_name.toLowerCase().includes(e.target.search.value)
      );
      changeOrder(filtered);
    }
  };

  return (
    <section className="search">
      <form onSubmit={submit} className="rb-search__form">
        <div className="rb-search__input-container">
          <input
            className="rb-search__input"
            type="text"
            name="search"
            placeholder="Search for a rabbit hole"
          />
          <button className="rb-search__btn">
            <img className="rb-search__btn-img" src={searchIcon} alt="search icon" />
          </button>
        </div>
      </form>
    </section>
  );
}

export default RabbitHoleSearch;

import React, {  useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import "./LogIn.scss";
const route = "/users/login";

function LogIn({ url }) {
  const [ error, setError] = useState("");
  const navigate = useNavigate();
  if(error){
    console.log('Hope your having fun with Rabbit Hole')
  }
  const handleSubmit = async (event) => {
    event.preventDefault();
    // Now that the user is registered we need to log thewm in
    // Make a POST request to "http://localhost:8080/api/users/login"
    // This post takes email and password
    // use response.data.token to retrieve token
    // sessionStorage to setItem to our token with the name token
    // use react router dom to navigate to home page

    try {
      const response = await axios.post(`${url}${route}`, {
        email: event.target.email.value,
        password: event.target.password.value,
      });
      // Store token to session storage
      sessionStorage.setItem("token", response.data.token);
      navigate("/");
    } catch (error) {
      setError("Something went wrong");
    }
  };
  const handleSignup = () => {
    navigate("/signup");
  };

  return (
    <>
    <form onSubmit={handleSubmit} className="login-form">
      <label className="login-form__label" htmlFor="email">
        Email
      </label>
      <input
        className="login-form__input"
        type="text"
        name="email"
        id="email"
        placeholder="Enter your email"
      />
      <label className="login-form__label" htmlFor="password">
        Password
      </label>
      <input
      className="login-form__input"
        type="password"
        name="password"
        id="password"
        maxLength="25"
        placeholder="Enter password"
      />
      <button className="login-form__login-btn" type="submit">
        Log-in
      </button>
      <h4 className="login-form__no-account">Don't have an account?</h4>
      <button className="login-form__signup-btn" onClick={handleSignup}>
        Click here to Sign Up
      </button>
    </form>
    <article className="login-contact">
    <h4 className="login-contact__text">Test Login <br/>
    email: test@123.com<br/>
    password: 123456789</h4>
      {/* <h4 className="login-contact__text">If you're having problems<br/>
      or have any suggestion for improvments<br/>
      Please email us at</h4> */}
      {/* <a className="login-contact__link" href="mailto:info@rabbitholebrowser.com">info@rabbitholebrowser.com</a> */}
      </article>
    </>

    // <Link to="/1" className="login-container">
    //   <button className="login-container__btn">Log-in</button>
    // </Link>
  );
}

export default LogIn;

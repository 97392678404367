import React from 'react'
import SignUp from '../Components/SignUp/SignUp'

function SignupPage({url}) {
  return (
    <>
      <SignUp url={url} />
    </>
  )
}

export default SignupPage

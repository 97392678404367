import React, { /* useState */ } from "react";
import "./RabbitHoleList.scss";
import { Link } from "react-router-dom";
// import DeleteModal from "../DeleteModal/DeleteModal";
// import deleteIcon from "../../asset/icons/delete-icon.svg";

function RabbitHoleList({ rBHoles, /* url, user */ }) {
  // const [deleteId, setDeleteId] = useState("");
  // const [activateModal, setActivateModal] = useState(false);

  // const handleClick = (e, holeId) => {
  //   e.preventDefault();
  //   setActivateModal(true);
  //   setDeleteId(holeId);
  // };

  return (
    <div>
      {/* <section
        // className={activateModal ? "delete-modal" : "delete_modal__hidden"}
        className="delete-modal"
      >
        <DeleteModal
          id={deleteId}
          user={user}
          url={url}
          active={activateModal}
        />
      </section> */}
      <section className="view-holes__container">
        {rBHoles.map((hole) => {
          return (
            <Link key={hole.id} to={`/rabbit/hole/${hole.id}`}>
              <section className="view-holes__sub-container">
                  <h3 className="view-holes__text">{hole.hole_name}</h3>
                  {/* <button onClick={(e) => handleClick(e, hole.id)}>
                    <img src={deleteIcon} alt="Delete Icon" />
                    {activateModal && (
                      <DeleteModal
                        id={deleteId}
                        user={user}
                        url={url}
                        active={activateModal}
                      />
                    )}
                  </button> */}
              </section>
            </Link>
          );
        })}
      </section>
    </div>
  );
}

export default RabbitHoleList;

import React, { useEffect, useState } from "react";
import RabbitHoleSearch from "../Components/RabbitHoleSearch/RabbitHoleSearch";
import RabbitHoleList from "../Components/RabbitHoleList/RabbitHoleList";
import {  useNavigate } from "react-router-dom";
import axios from "axios";

function ViewRabbitHoles({ url }) {
const navigate = useNavigate()
  const [user, setUser] = useState(null);
	const [failedAuth, setFailedAuth] = useState(false);

	// when the component mounts
	useEffect(() => {
		// getItem from sessionStorage token
		const token = sessionStorage.getItem('token');

		// If theres not a token then setFailedAuth to true and return 
		if(!token) {
			setFailedAuth(true)
		}
		// Otherwise we will check to see if the current user is authorized to be on this dashboard

		
		const authorizeUser = async () => {
			try {
				// Make a get request to "http://localhost:8080/api/users/current"
				const response = await axios.get(`${url}/users/current`, {
					headers: {
						Authorization: `Bearer ${token}`
					}
				})

				setUser(response.data)
				// Pass bearer token in the headers
				// set user as response.data
	
				
			} catch(error) { 
				console.error(error);
				setFailedAuth(true)
			}
		}
		authorizeUser()

		// Pass Headers on this request 
		// use the Authorization key to pass a Bearer token
		// Use string interpolation to pass `Bearer ${token}` as value for authorization
		// On successful response setUser to response.data
		// On failure setFailed auth to true

// eslint-disable-next-line react-hooks/exhaustive-deps 
	}, []);
  useEffect(()=>{
    if (failedAuth) {
      navigate("/login");
    }

  })

  const route = "/rabbit/holes/user";

  const [rBHoles, setRBHoles] = useState([]);
  const [refresh, setRefresh] = useState();
  if(refresh){
	console.log('Hope your having fun with Rabbit Hole')
  }
  useEffect(() => {
    const getRabbitHoles = async () => {
      const response = await axios.get(`${url}${route}`,{
        params: { user_id: user?.id }
      });
      setRBHoles(response.data);
    };
    if(user){
          getRabbitHoles();

    }
// eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);
  return (
    <>
      <RabbitHoleSearch changeOrder={setRBHoles} rBHoles={rBHoles} refresh={setRefresh} />
      <RabbitHoleList rBHoles={rBHoles} url={url} user={user}  />
    </>
  );
}

export default ViewRabbitHoles;

import React, {  useState } from "react";
import "./NewRabbitHole.scss";
import axios from "axios";

function NewRabbitHole({ url, userId, refresh }) {
  const route2 = "/rabbit/holes"
  const [ newRabbitHole, setNewRabbitHole] = useState(null);
  if(newRabbitHole){
    console.log('Hope your having fun with Rabbit Hole')
  }
  const handleSubmit = async (e) => {
    e.preventDefault();
    const rabbitHoleInfo = {
      user_id: userId,
      hole_name: e.target.rbname.value,
    };
    if (e.target.rbname.value) {
      await axios.post(`${url}${route2}`, rabbitHoleInfo).then((response) => {
        setNewRabbitHole(response.data.id);
        refresh(!refresh);
        refresh(!refresh);

        e.target.rbname.value = "";
      });
    }
  };
  return (
    <div>
      <form onSubmit={handleSubmit} className="new-hole">
        <input
          className="new-hole__txt-box"
          name="rbname"
          type="text"
          placeholder="Enter New Rabbit Hole Name"
          maxLength="36"
        />
        <button className="new-hole__btn">
          <h1>DIG</h1>
        </button>
      </form>
    </div>
  );
}

export default NewRabbitHole;

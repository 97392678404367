import React, { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import axios from "axios";
import "./Search.scss";
import searchIcon from "../../asset/icons/search-icon.svg";
import { v4 as uuidv4 } from "uuid";

function Search({ url, user }) {
  const route = "/rabbit/holes/single/";
  const route2 = "/tunnels/links";
  const route3 = "/tunnels/";
  const route4 = "/rabbit/holes/past/searches/";

  const { id } = useParams();
  const [rBHoles, setRBHoles] = useState([]);
  const [webLinks, setWebLinks] = useState([]);
  const [recentSearches, setRecentSearches] = useState([]);

  useEffect(() => {
    const getRabbitHoles = async () => {

      const user_id = { user_id: user.id };
      try {
        const response = await axios.post(`${url}${route}${id}`, user_id);
        setRBHoles(response.data);
      } catch (error) {
        console.error("(front-end)Error fetching rabbit holes:", error.message);
      }
    };

    const getPastSearches = async () => {
      const response = await axios.get(`${url}${route4}${id}`);
      setRecentSearches(response.data);
    };

    getPastSearches();
    if (user) {
      getRabbitHoles();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id,user]);
  const [isSearching, setIsSearching] = useState("");

  const submit = (e) => {
    e.preventDefault();
    setIsSearching(e.target.search.value);
  };
  useEffect(() => {
    const getLinks = async () => {
      const linkChange = isSearching.split(" ");
      const linkJoin = linkChange.join("+");
      const response = await axios.post(`${url}${route2}`, { q: linkJoin });
      setWebLinks(response.data.web?.results);

      if (isSearching) {
        await axios.post(`${url}${route4}${id}`, {
          rabbit_hole_id: id,
          search_text: isSearching,
        });
      }
    };
    if (isSearching) {
      getLinks();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSearching]);

  const linkClick = async (e, urlLink, title) => {
    e.preventDefault();
    await axios.post(`${url}${route3}`, {
      rabbit_hole_id: id,
      user_id: user.id,
      tunnel_name: title,
      url: urlLink,
    });

    window.open(
      urlLink,
      '_blank' // <- This is what makes it open in a new window.
    );
  };
  const pastSearchClick = async (e, searchLink) => {
    e.preventDefault();
    setIsSearching(searchLink);
  };
  const rabbitHoleLink = `/rabbit/hole/${id}`;

  return (
    <section className="search">
      <Link to={rabbitHoleLink}>
        <h1 className="search__rb-name">{rBHoles.hole_name}</h1>
      </Link>
      <form onSubmit={submit} className="search__form">
        <div className="search__input-container">
          <input
            className="search__input"
            type="text"
            name="search"
            placeholder="Search Something"
          />
          <button className="search__btn">
            <img src={searchIcon} alt="search icon" />
          </button>
        </div>
      </form>

      <section className="search-container">
        {webLinks &&
          webLinks.map((link) => {
            const text = link.description;
            const result = text.replace(/<strong>/g, "");
            const result2 = result.replace(/<\/strong>/g, "");

            return (
              <article
                className="search-link"
                onClick={(e) => linkClick(e, link.url, link.title)}
                key={uuidv4()}
              >
                <article className="search-link__container">
                  <article className="search-link__header">
                    <img
                      className="search-link__img"
                      src={link.profile.img}
                      alt=""
                    />
                    <article className="search-link__img-title-container">
                      <h3 className="search-link__title">
                        {link.meta_url.netloc}
                      </h3>
                      <p className="search-link__url">{link.url}</p>
                    </article>
                  </article>
                  <article className="search-link__description-container">
                    <h2 className="search-link__description-header">
                      {link.title}
                    </h2>
                    <h4 className="search-link__description">{result2}</h4>
                  </article>
                </article>
              </article>
            );
          })}
      </section>

      <article
        className={isSearching ? "search__recent--hidden" : "search__recent"}
      >
        <article
          className={
            recentSearches?.length ? "search__recent--hidden" : "search__recent"
          }
        >
          <p className="search__recent-txt">------ No Recent Searches ------</p>
        </article>
      </article>
      {recentSearches?.map((search) => {
        return (
          <h4
            onClick={(e) => pastSearchClick(e, search.search_text)}
            className={
              recentSearches.length
                ? "search__previous"
                : "search__previous--hidden"
            }
          >
            {search.search_text}
          </h4>
        );
      })}
    </section>
  );
}

export default Search;

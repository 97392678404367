import React from "react";
import "./RecentRabbitHoles.scss";
import { Link} from "react-router-dom";


function RecentRabbitHoles({ rBHoles }) {

  rBHoles?.sort((a, b) => b.id - a.id);

 
  const slicedResponse = rBHoles.slice(0,6);

  return (
    <section className="recent-holes">
      <Link className="recent-holes__view-all-btn" to={`/rabbit/holes/`}>
        VIEW ALL
      </Link>
      <h3 className="recent-holes__header">RECENTLY CREATED</h3>
      <section className="recent-holes__container">
       {slicedResponse?.map((hole) => {
          return(
            <Link key={hole.id} to={`/rabbit/hole/${hole.id}`}>
                      <section  className="recent-holes__sub-container">
              <h3 className="recent-holes__text">{hole.hole_name}</h3>
          </section>

            </Link>
        )})}
      </section>
    </section>
  );
}

export default RecentRabbitHoles;

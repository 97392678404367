import React, { useEffect, useState } from "react";
import menuButton from "../../asset/icons/menu_black_24dp.svg";
import "./NavBar.scss";
import { NavLink, Link } from "react-router-dom";
import axios from "axios";

function NavBar({  url }) {
  const [navBar, setNavBar] = useState(false);
  const [ user, setUser] = useState(null);
	const [failedAuth, setFailedAuth] = useState(false);
  if(user){
    console.log('Hope your having fun with Rabbit Hole')
  }
	// when the component mounts
	useEffect(() => {
		// getItem from sessionStorage token
		const token = sessionStorage.getItem('token');

		// If theres not a token then setFailedAuth to true and return 
		if(!token) {
			setFailedAuth(true)
		}
		// Otherwise we will check to see if the current user is authorized to be on this dashboard
if(url){
      console.log(`url test ${url}`);

}

		const authorizeUser = async () => {
			try {
				// Make a get request to "http://localhost:8080/api/users/current"
				const response = await axios.get(`${url}/users/current`, {
					headers: {
						Authorization: `Bearer ${token}`
					}
				})

				setUser(response.data)
				// Pass bearer token in the headers
				// set user as response.data
	
				
			} catch(error) { 
				console.error(error);
				setFailedAuth(true)
			}
		}
    if(url){
      authorizeUser()

    }

		// Pass Headers on this request 
		// use the Authorization key to pass a Bearer token
		// Use string interpolation to pass `Bearer ${token}` as value for authorization
		// On successful response setUser to response.data
		// On failure setFailed auth to true

    // eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
  const handleLogout = () => {
		// Remove token from storage 
		sessionStorage.removeItem("token");
		setUser(null);
		setFailedAuth(true);
    setNavBar(!navBar);
    setNavBar(!navBar);
	};

  const navBarMenu = () => {
    setNavBar(!navBar);
  
  setNavBar(!navBar);
}

return (
  <nav className="nav-bar">
    <img
      onClick={navBarMenu}
      className="nav-bar__menu-btn"
      src={menuButton}
      alt="Menu Button"
    />
    <section
      className={navBar ? "nav-bar__drop-down" : "nav-bar__drop-down--hidden"}
    >
      <NavLink
        to={`/`}
        onClick={navBarMenu}
        className="nav-bar__link"
      >
        Home
      </NavLink>
      <NavLink
        to={`/rabbit/holes/`}
        onClick={navBarMenu}
        className="nav-bar__link"
      >
        Rabbit Holes
      </NavLink>
      <NavLink
        to={`/login`}
        onClick={handleLogout}
        className="nav-bar__link"
      >
       {failedAuth ? 'Login' : 'Logout'}
      </NavLink>
    </section>
    <Link to={`/`}>
      <article className="nav-bar__logo-bg">
        <h1 className="nav-bar__logo-txt">RABBIT HOLE</h1>
      </article>
    </Link>

  </nav>
);
}
export default NavBar;

import React, { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import axios from "axios";
import "./SingleRabbitHole.scss";
import searchIcon from "../../asset/icons/search-icon.svg";

function SingleRabbitHole({ url, user }) {
  const route = "/tunnels/";
  const route2 = "/rabbit/holes/single/";

  const { id } = useParams();
  const searchLink = `/search/${id}`;

  const [tunnels, setTunnels] = useState([]);
  const [rBHoles, setRBHoles] = useState([]);
  useEffect(() => {
    const getTunnels = async () => {
      try {
        const response = await axios.post(`${url}${route}${id}`, {
          user_id: user?.id,
        });
        setTunnels(response.data);
      } catch (error) {
        console.error("Error fetching tunnels:", error.message);
      }
    };
    const getRabbitHoles = async () => {
      const user_id = { user_id: user.id };
      try {
        const response = await axios.post(`${url}${route2}${id}`, user_id);
        setRBHoles(response.data);
      } catch (error) {
        console.error("(front-end)Error fetching rabbit holes:", error.message);
      }
    };

    if (user) {
      getTunnels();
      getRabbitHoles();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);


  
  return (
    <section>
      <article className="saved-links__header">
        <h1>{rBHoles.hole_name}</h1>
        <Link to={searchLink} className="saved-links__header-link">
          <img src={searchIcon} alt="search icon" />
          Search
        </Link>
      </article>
      <section className="saved-links">
        {tunnels.map((tunnel) => {
          return (
            <>
              <a className="saved-links__container" href={tunnel.url} target="_blank" rel="noopener noreferrer">
                <article className="saved-links__sub-container" key={tunnel.id}>
                  <h2 className="saved-links__tunnel-name">
                    {tunnel.tunnel_name}
                  </h2>
                  <h4 className="saved-links__url">{tunnel.url}</h4>
                </article>
              </a>
            </>
          );
        })}
      </section>
    </section>
  );
}

export default SingleRabbitHole;

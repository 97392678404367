import React from 'react'
import LogIn from '../Components/LogIn/LogIn'


function LoginPage({url}) {
  return (
    <div>
      <LogIn url={url} />
    </div>
  )
}

export default LoginPage

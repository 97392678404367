import "./App.scss";
import {
  BrowserRouter,
  Routes,
  Route,

} from "react-router-dom";
import HomePage from "./pages/HomePage";
import NavBar from "./Components/NavBar/NavBar";
import SearchView from "./pages/SearchView";
import ViewRabbitHoles from "./pages/ViewRabbitHoles";
import RabbitHole from "./pages/RabbitHole";
import LoginPage from "./pages/LoginPage";
import SignupPage from "./pages/SignupPage";

function App() {
  const url = process.env.REACT_APP_API_URL;

  return (
    <>
      <BrowserRouter>
        <NavBar url={url} />
        <main>
          <Routes>
            <Route
              path="/login"
              element={
                <LoginPage
                url={url}
                />
              }
            />
            <Route path="/signup" element={<SignupPage url={url} />} />
            <Route path="/undefined" element={<LoginPage url={url} />} />
            <Route path="/" element={<HomePage url={url} />} />
            <Route path="/rabbit/hole/:id" element={<RabbitHole url={url} />} />
            <Route
              path="/search/:id"
              element={<SearchView url={url} />}
            />
            <Route
              path="/rabbit/holes/"
              element={<ViewRabbitHoles url={url} />}
            />
          </Routes>
        </main>
      </BrowserRouter>
    </>
  );
}

export default App;

import React from "react";
import "./SignUp.scss";
import axios from "axios";
import { useNavigate } from "react-router-dom";

function SignUp({ url }) {
  const navigate = useNavigate();
  const route = "/users";
  const submit = async (e) => {
    e.preventDefault();
    const pass1 = e.target.password.value;
    const pass2 = e.target.confirmpassword.value;
    if (pass1 === pass2 && pass1.split("").length >= 8) {
    await axios.post(`${url}${route}`, {
        first_name: e.target.firstname.value,
        last_name: e.target.lastname.value,
        email: e.target.email.value,
        password: e.target.password.value,
      });
    }
    navigate("/");
  };
  return (
    <>
      <form onSubmit={submit} className="signup-form">
        <label className="signup-form__label" htmlFor="firstname">First Name</label>
        <input
        className="signup-form__input"
          type="text"
          name="firstname"
          id="firstname"
          placeholder="Enter your first name"
        />
        <label className="signup-form__label" htmlFor="lastname">Last Name</label>
        <input
        className="signup-form__input"
          type="text"
          name="lastname"
          id="lastname"
          placeholder="Enter last name"
        />
        <label className="signup-form__label" htmlFor="email">Email</label>
        <input
        className="signup-form__input"
          type="text"
          name="email"
          id="email"
          placeholder="Enter your email"
        />
        <label className="signup-form__label" htmlFor="password">Password</label>
        <input
        className="signup-form__input"
          type="password"
          name="password"
          id="password"
          maxLength="25"
          placeholder="minimum 8 characters"
        />
        <label className="signup-form__label" htmlFor="confirmpassword">Confirm Password</label>
        <input
        className="signup-form__input"
          type="password"
          name="confirmpassword"
          id="confirmpassword"
          maxLength="25"
          placeholder="minimum 8 characters"
        />
        <button className="signup-form__login-btn" type="submit">Sign-up</button>
      </form>
      <article className="signup-contact">
      <h4 className="signup-contact__text">If you're having problems<br/>
      or have any suggestion for improvments<br/>
      Please email us at</h4>
      <a className="signup-contact__link" href="mailto:info@rabbitholebrowser.com">info@rabbitholebrowser.com</a>
      </article>
    </>
  );
}

export default SignUp;
